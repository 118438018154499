import { FC } from 'react'
import { SvgProps } from '@/components/atoms/svg/svgProps'
const SweatersWomen: FC<SvgProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width || 18}
      height={height || 18}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116.65 154"
    >
      <use href="#sweaters-women-icon-definition" stroke={color || '#000'} />
    </svg>
  )
}
export default SweatersWomen
